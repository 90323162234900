import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MarketingPage from './MarketingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DataDeletionPolicy from './pages/DataDeletionPolicy';
import { Helmet } from 'react-helmet';

export default function App() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ParcelDelivery",
    "name": "Skala Red Logística",
    "description": "Conectamos, Optimizamos y Protegemos: Soluciones Integrales para el Transporte de Mercancías Especializadas.",
    "url": "https://skalaredlogistica.com/",
    "telephone": "+57 320 3085398"
  };

  return (
    <Router>
      <Helmet>
        <title>Skala Red Logística | Conectamos, Optimizamos y Protegemos: Soluciones Integrales para el Transporte de Mercancías Especializadas</title>
        <meta
          name="description"
          content="Skala Red Logística: especialistas en carga frágil y doble altura. Con tecnología de doble nivel, reducimos roturas en un 30% y costos logísticos."
        />
        <meta property="og:title" content="Skala Red Logística | Conectamos, Optimizamos y Protegemos: Soluciones Integrales para el Transporte de Mercancías Especializadas" />
        <meta
          property="og:description"
          content="Optimiza el transporte de mercancías delicadas a nivel nacional con tecnología de doble nivel."
        />
        <meta property="og:image" content="https://skalaredlogistica.com/static/images/skala.png" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/data-deletion-policy" element={<DataDeletionPolicy />} />
      </Routes>
    </Router>
  );
}