import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailCenter from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import SitemarkIcon from "./SitemarkIcon";
import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
      {"Copyright © "}
      <Link component={RouterLink} to="/" color="text.secondary">
        Skala Red Logística
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      id="Contacto"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <SitemarkIcon />
            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              ¡Contáctanos!
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
              Teléfonos: <Link href="tel:+573203085398">+57 320 3085398</Link>{" "}
              <Link href="tel:+573115747716">+57 311 5747716</Link>
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
              Correo Electrónico:{" "}
              <Link href="mailto:leonardo.moyano@skalaredlogistica.com">
                leonardo.moyano@skalaredlogistica.com
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Skala
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Beneficios
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Características
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Soluciones
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Compañia
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Nosotros
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Trabaja con nosotros
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            Legal
          </Typography>
          <Link
            component={RouterLink}
            to="/terms-of-service"
            color="text.secondary"
            variant="body2"
          >
            Términos
          </Link>
          <Link
            component={RouterLink}
            to="/privacy-policy"
            color="text.secondary"
            variant="body2"
          >
            Privacidad
          </Link>
          <Link
            component={RouterLink}
            to="/data-deletion-policy"
            color="text.secondary"
            variant="body2"
          > 
            Eliminación de datos
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="#">
            Politica de privacidad
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terminos de servicio
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://api.whatsapp.com/send/?phone=573203085398&text=Buen%20día!%20Vengo%20desde%20la%20pagina%20de%20Skala,%20estoy%20interesado%20en%20..."
            aria-label="WhatsApp"
            sx={{ alignSelf: "center" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="tel:+573203085398"
            aria-label="Telefono"
            sx={{ alignSelf: "center" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/skalaredlogistica/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="mailto:leonardo.moyano@skalaredlogistica.com"
            aria-label="Correo Electronico"
            sx={{ alignSelf: "center" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailCenter />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
