import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>Política de Privacidad - Skala Red Logística</title>
        <meta
          name="description"
          content="Política de tratamiento de datos personales de Skala Red Logística S.A.S."
        />
      </Helmet>

      <Box mb={4}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            mb: 2,
            color: "#ed1b24",
          }}
        >
          POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES
        </Typography>

        <Typography variant="h6" gutterBottom>
          Skala Red Logística S.A.S.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Última actualización: 03/03/2025
        </Typography>
      </Box>

      {/* Sección I. INTRODUCCIÓN */}
      <Section title="I. INTRODUCCIÓN">
        <Typography paragraph>
          Skala Red Logística S.A.S. (en adelante, “Skala” o “la Compañía”) es
          una sociedad constituida bajo las leyes de la República de Colombia,
          cuya principal actividad consiste en conectar y coordinar servicios de
          transporte terrestre de mercancías, vehículos y logística en general,
          a través de diversas plataformas tecnológicas, tales como (i) página
          web (https://www.skalaredlogistica.com/), (ii) aplicaciones móviles,
          (iii) integración con servicios de mensajería instantánea (como
          WhatsApp u otras herramientas) y (iv) soluciones de software para la
          gestión de logística de carga.
        </Typography>
        <Typography paragraph>
          En cumplimiento de la Ley 1581 de 2012, el Decreto 1074 de 2015 y
          demás normas concordantes y complementarias, Skala adopta la presente
          Política de Tratamiento de Datos Personales (en adelante “la
          Política”), cuyo fin principal es garantizar y proteger el derecho
          fundamental al Habeas Data, así como el debido tratamiento de los
          datos personales de quienes establecen relaciones con la Compañía:
          clientes, conductores, gestores de carga, generadores de carga,
          proveedores, aliados, socios, usuarios de la aplicación y demás
          personas naturales cuyos datos personales sean objeto de tratamiento
          por parte de Skala.
        </Typography>
        <Typography paragraph>
          Esta Política describe los lineamientos generales corporativos para
          salvaguardar la privacidad de los datos personales, las finalidades de
          su tratamiento, el área responsable de atender peticiones o reclamos,
          y los canales y procedimientos a través de los cuales el titular de
          los datos puede ejercer sus derechos de conocimiento, actualización,
          rectificación y supresión de la información personal.
        </Typography>
      </Section>

      {/* Sección II. DEFINICIONES */}
      <Section title="II. DEFINICIONES">
        <Typography paragraph>
          A efectos de la presente Política, aplican las definiciones
          establecidas en la Ley 1581 de 2012, el Decreto 1074 de 2015, y demás
          normas que las modifiquen, adicionen o complementen. Entre otras, se
          destacan:
        </Typography>
        <List dense>
          <DefinitionItem
            term="Dato personal:"
            definition="Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables."
          />
          <DefinitionItem
            term="Titular:"
            definition="Persona natural cuyos datos personales son objeto de tratamiento."
          />
          <DefinitionItem
            term="Base de datos personales:"
            definition="Conjunto organizado de datos personales objeto de tratamiento."
          />
          <DefinitionItem
            term="Dato sensible:"
            definition="Información que afecta la intimidad del titular o cuyo uso indebido puede generar discriminación (por ejemplo, datos de salud, orientación política, religiosa, etc.)."
          />
          <DefinitionItem
            term="Autorización:"
            definition="Consentimiento previo, expreso e informado del titular para llevar a cabo el tratamiento de datos personales."
          />
          <DefinitionItem
            term="Responsable del tratamiento:"
            definition="Persona natural o jurídica que decide sobre la base de datos y/o el tratamiento. Para los fines de esta Política, Skala Red Logística S.A.S. es la responsable."
          />
          <DefinitionItem
            term="Encargado del tratamiento:"
            definition="Persona natural o jurídica que realiza el tratamiento de datos personales por cuenta del responsable."
          />
          <DefinitionItem
            term="Tratamiento:"
            definition="Cualquier operación sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión."
          />
          <DefinitionItem
            term="Transmisión:"
            definition="Comunicación de datos personales a un encargado, dentro o fuera del territorio nacional, para la realización de un tratamiento por cuenta del responsable."
          />
          <DefinitionItem
            term="Transferencia:"
            definition="Envío de datos personales a un receptor, dentro o fuera de Colombia, que a su vez se convierte en responsable de la información."
          />
        </List>
      </Section>

      {/* Sección III. PRINCIPIOS */}
      <Section title="III. PRINCIPIOS QUE RIGEN EL TRATAMIENTO DE DATOS PERSONALES">
        <Typography paragraph>
          De conformidad con el Título II de la Ley 1581 de 2012, el tratamiento
          de datos personales se sujeta a los siguientes principios:
        </Typography>
        <List>
          {[
            "Legalidad: El tratamiento es una actividad reglada que debe sujetarse a lo establecido en la Ley 1581 de 2012 y demás normas aplicables.",
            "Finalidad: El tratamiento debe obedecer a una finalidad legítima, la cual se le informará al titular al momento de la recolección de sus datos.",
            "Libertad: El tratamiento sólo puede ejercerse con la autorización del titular, previa, expresa e informada.",
            "Veracidad o Calidad: La información objeto de tratamiento debe ser completa, exacta, actualizada, comprobable y comprensible.",
            "Transparencia: Debe garantizarse al titular el derecho a obtener en cualquier momento información acerca de la existencia de datos que le conciernan.",
            "Seguridad: La información sujeta a tratamiento debe manejarse con las medidas técnicas, humanas y administrativas necesarias para evitar su adulteración, pérdida, consulta, uso o acceso no autorizado.",
            "Confidencialidad: Todas las personas que intervengan en el tratamiento de datos personales no públicos están obligadas a garantizar su reserva, incluso después de finalizada su relación con alguna de las labores del tratamiento.",
            "Acceso y circulación restringida: El tratamiento se sujeta a los límites que se deriven de la naturaleza de los datos y de la legislación vigente.",
          ].map((item, index) => (
            <ListItem key={index} sx={{ alignItems: "flex-start" }}>
              <ListItemText
                primary={`${index + 1}. ${item}`}
                primaryTypographyProps={{ variant: "body1" }}
              />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección IV. DERECHOS */}
      <Section title="IV. DERECHOS DE LOS TITULARES">
        <Typography paragraph>
          Los titulares de los datos personales (conductores, gestores de carga,
          generadores de carga, clientes, proveedores, socios, entre otros)
          tendrán los siguientes derechos, sin perjuicio de lo establecido en la
          Ley 1581 de 2012:
        </Typography>
        <List>
          {[
            "Acceder a sus datos personales que sean objeto de tratamiento.",
            "Actualizar la información cuando se encuentre desactualizada.",
            "Rectificar los datos que sean inexactos o incompletos.",
            "Oponerse al tratamiento de los datos en los casos en que la ley lo permita.",
            "Solicitar la supresión de sus datos personales si no se respetan los principios, derechos y garantías constitucionales y legales.",
            "Revocar la autorización otorgada para el tratamiento de datos, siempre que no lo impida una obligación legal o contractual.",
            "Solicitar prueba de la autorización otorgada a Skala para el tratamiento de datos personales.",
            "Presentar quejas ante la Superintendencia de Industria y Comercio por eventuales infracciones a la normativa de protección de datos.",
          ].map((right, index) => (
            <ListItem key={index} sx={{ alignItems: "flex-start" }}>
              <ListItemText
                primary={`${index + 1}. ${right}`}
                primaryTypographyProps={{ variant: "body1" }}
              />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección V. RECOLECCIÓN Y USO */}
      <Section title="V. RECOLECCIÓN Y USO DE DATOS PERSONALES">
        <Typography paragraph>
          Skala Red Logística S.A.S. recolecta información personal a través de
          múltiples canales y con diversas finalidades, siempre con la
          autorización previa, expresa e informada del titular, o en los casos
          en que la ley no exija dicha autorización. A continuación, se
          describen las principales categorías de datos que podríamos recolectar
          y tratar:
        </Typography>
        <DataCollectionItem
          title="1. Datos de ubicación (georreferenciación)"
          descriptions={[
            "Recopilados a través de aplicaciones móviles (para conductores, gestores de carga, generadores de carga o personal logístico) incluso cuando la aplicación esté en segundo plano o no se esté utilizando activamente, siempre y cuando el titular haya otorgado los permisos correspondientes en el dispositivo.",
            "Utilizados para asignar servicios de transporte, optimizar rutas, realizar seguimiento de entregas y garantizar la seguridad del servicio.",
          ]}
        />

        <DataCollectionItem
          title="2. Documentos e imágenes"
          descriptions={[
            "Documentos de identidad, licencias de conducción, SOAT, tarjeta de propiedad del vehículo, permisos de operación, entre otros, según aplique a conductores y vehículos.",
            "Imágenes asociadas al vehículo, conductor, guías de carga, facturas o comprobantes, con fines de validación, control y seguimiento.",
          ]}
        />

        <DataCollectionItem
          title="3. Datos de vehículo y conductor"
          descriptions={[
            "Marca, placa, tipo de carrocería, capacidad de carga, documentación vigente, pólizas de seguro y cualquier otro requisito legal para la prestación del servicio.",
            "Datos de experiencia, referencias, certificaciones, disponibilidad y ubicación.",
          ]}
        />

        <DataCollectionItem
          title="4. Datos de clientes, usuarios y gestores de carga"
          descriptions={[
            "Nombre completo, número de identificación, datos de contacto (teléfono, dirección de correo electrónico), dirección física, información de facturación, entre otros que resulten necesarios para la prestación del servicio.",
            "Información suministrada a través del sitio web, formularios, aplicaciones y/o WhatsApp.",
          ]}
        />

        <DataCollectionItem
          title="5. Datos recopilados automáticamente (cookies y tecnologías similares)"
          descriptions={[
            "Dirección IP, tipo de navegador, secciones visitadas, tiempo de permanencia, sistemas operativos y configuraciones del dispositivo.",
            "Estos datos ayudan a mejorar la experiencia de usuario, personalizar contenidos y optimizar la seguridad de la plataforma.",
          ]}
        />

        <DataCollectionItem
          title="6. Otras fuentes de recolección"
          descriptions={[
            "WhatsApp y otras herramientas de mensajería: Conversaciones en las que se intercambien datos de contacto, documentación, imágenes, entre otros, según sea necesario para atender solicitudes de servicio o reclamos.",
            "Proveedores y aliados estratégicos: Cuando se reciba de forma legítima información de terceros con fines de colaboración o soporte logístico.",
            "Eventos presenciales o virtuales: Recopilación de datos de identificación o contacto cuando los titulares participan en actividades promocionales, capacitaciones, ferias, entre otros.",
          ]}
        />
      </Section>

      {/* Sección VI. FINALIDADES DEL TRATAMIENTO */}
      <Section title="VI. FINALIDADES DEL TRATAMIENTO">
        <Typography paragraph>
          Los datos personales serán tratados por Skala para los siguientes
          fines:
        </Typography>

        <List
          dense
          sx={{ "& .MuiListItem-root": { alignItems: "flex-start" } }}
        >
          {[
            {
              title: "Prestación de servicios logísticos",
              description:
                "Para la adecuada gestión de transporte, recolección y entrega de mercancías, monitoreo de rutas, gestión de cargas, seguimiento en tiempo real y optimización de procesos logísticos.",
            },
            {
              title: "Gestión de vehículos y conductores",
              description:
                "Validar la documentación, asegurar el cumplimiento legal y operativo, asignar servicios, enviar información relevante sobre mantenimientos, seguros, rutas y horarios.",
            },
            {
              title: "Mejora continua y control interno",
              description:
                "Analizar la información para el desarrollo, implementación y evaluación de mejoras en la plataforma tecnológica, aplicaciones y servicios asociados.",
            },
            {
              title: "Comunicación y notificaciones",
              description:
                "Enviar información sobre actualizaciones de la plataforma, recordatorios de ruta, información operativa, promociones y campañas de mercadeo, siempre respetando la normatividad de spam y publicidad.",
            },
            {
              title: "Procesos administrativos y contables",
              description:
                "Emitir facturas, cotizaciones, gestionar cobros, pagos, conciliaciones y demás operaciones de carácter administrativo.",
            },
            {
              title: "Seguridad y prevención de fraude",
              description:
                "Verificar la autenticidad de la información, prevenir y detectar fraudes, operaciones ilícitas o usos indebidos de la plataforma.",
            },
            {
              title: "Cumplimiento legal",
              description:
                "Responder a requerimientos de autoridades competentes o en virtud de obligaciones legales y contractuales.",
            },
            {
              title: "Transmisión y/o Transferencia de datos",
              description:
                "A terceros aliados, proveedores de tecnología y servicios, nacionales o internacionales, siempre que sea necesario para cumplir la finalidad del tratamiento y de acuerdo con la legislación aplicable.",
            },
          ].map((item, index) => (
            <ListItem key={index} sx={{ py: 1 }}>
              <ListItemText
                primary={
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {index + 1}. {item.title}:
                  </Typography>
                }
                secondary={
                  <Typography component="span" variant="body1">
                    {item.description}
                  </Typography>
                }
                secondaryTypographyProps={{ component: "span" }}
              />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección VII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR */}
      <Section title="VII. AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR">
        <List
          dense
          sx={{ "& .MuiListItem-root": { alignItems: "flex-start" } }}
        >
          {[
            {
              title: "1. Obtención de la autorización:",
              points: [
                "Skala solicitará autorización para la recolección y tratamiento de datos personales mediante los canales habituales (formularios de registro, aplicaciones móviles, sitio web, acuerdos contractuales, casillas de aceptación).",
                "La autorización será previa, expresa e informada, pudiendo el titular rechazar o retirar la misma en cualquier momento, siempre que no exista una obligación legal o contractual que lo impida.",
              ],
            },
            {
              title: "2. Tratamiento de datos recolectados con anterioridad:",
              points: [
                "Cuando existan bases de datos con información recolectada antes de la entrada en vigencia de la Ley 1581 de 2012 o el Decreto 1377 de 2013, Skala aplicará los mecanismos de contacto y regularización de autorización previstos en la normativa, siempre buscando la ratificación de la autorización del titular.",
              ],
            },
            {
              title: "3. Datos sensibles:",
              points: [
                "Skala se abstendrá de recolectar datos sensibles salvo en los casos estrictamente necesarios y con autorización expresa.",
                "La Compañía no condicionará ninguna actividad a la entrega de datos sensibles que no sean pertinentes para el objeto de la relación contractual o la prestación del servicio.",
              ],
            },
          ].map((section, index) => (
            <ListItem key={index} sx={{ display: "block", py: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                {section.title}
              </Typography>
              <List dense disablePadding sx={{ pl: 2 }}>
                {section.points.map((point, pointIndex) => (
                  <ListItem
                    key={pointIndex}
                    sx={{ py: 0, display: "list-item" }}
                  >
                    <ListItemText
                      primary={point}
                      primaryTypographyProps={{
                        variant: "body1",
                        component: "span",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección VIII. TRANSMISIÓN Y TRANSFERENCIA */}
      <Section title="VIII. TRANSMISIÓN Y TRANSFERENCIA NACIONAL O INTERNACIONAL">
        <Typography paragraph>
          Skala podrá transmitir y/o transferir datos personales a terceros en
          Colombia o en el extranjero, siempre que:
        </Typography>
        <List dense>
          {[
            "Exista autorización previa del titular o se configure una de las excepciones legales.",
            "El país receptor cuente con un nivel adecuado de protección de datos personales, de acuerdo con lo dispuesto en la Ley 1581 de 2012 y demás normas aplicables, o se suscriban acuerdos que garanticen la protección de los datos conforme a los estándares colombianos.",
          ].map((item, index) => (
            <ListItem
              key={index}
              sx={{ display: "list-item", listStyleType: "decimal", pl: 2 }}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección IX. MEDIDAS DE SEGURIDAD */}
      <Section title="IX. MEDIDAS DE SEGURIDAD Y CONFIDENCIALIDAD">
        <Typography paragraph>
          Skala implementa medidas de seguridad de tipo técnico, humano y
          administrativo para proteger la información de accesos no autorizados,
          alteraciones, pérdidas o uso indebido. Solo el personal autorizado y
          los terceros que cumplan con los requisitos de confidencialidad pueden
          acceder a los datos personales almacenados en nuestras bases de datos.
        </Typography>
        <Typography paragraph>
          En el evento de un incidente de seguridad que comprometa datos
          personales, Skala notificará a los titulares y a la Superintendencia
          de Industria y Comercio, de conformidad con la normativa aplicable.
        </Typography>
      </Section>

      {/* Sección X. CANALES Y PROCEDIMIENTOS */}
      <Section title="X. CANALES Y PROCEDIMIENTOS PARA EL EJERCICIO DE DERECHOS">
        <Typography paragraph>
          Con el fin de garantizar el Habeas Data, los titulares, sus
          causahabientes o representantes legales podrán presentar consultas,
          peticiones, quejas o reclamos relacionados con el tratamiento de sus
          datos personales, a través de los siguientes canales de atención:
        </Typography>

        <List dense sx={{ pl: 4, listStyleType: "disc" }}>
          {[
            "Correo electrónico: contacto@skalaredlogistica.com",
            "Atención telefónica:  +57 320 3085398",
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item", pl: 1 }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>

        <Box mt={2}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            1. Consultas (solicitudes de información)
          </Typography>
          <List dense sx={{ pl: 4 }}>
            {[
              "Se atenderán en un término máximo de 10 días hábiles contados a partir del día siguiente a la recepción de la solicitud.",
              "Si no fuera posible atender dentro de ese plazo, se informará al solicitante los motivos de la demora y la fecha de respuesta, que no podrá exceder de 5 días hábiles adicionales.",
            ].map((item, index) => (
              <ListItem key={index} sx={{ display: "list-item" }}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box mt={2}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            2. Reclamos
          </Typography>
          <List dense sx={{ pl: 4 }}>
            {[
              "Se resolverán en un plazo máximo de 15 días hábiles contados a partir del día siguiente a la recepción del reclamo.",
              "Si no fuera posible atender dentro de ese plazo, se informará al interesado los motivos de la demora y la fecha en que será atendido, que no podrá superar 8 días hábiles adicionales.",
              "Si el reclamo está incompleto, se requerirá la subsanación dentro de los 5 días hábiles siguientes a la recepción; transcurridos 2 meses sin que el solicitante corrija la información, se entenderá desistida la solicitud.",
            ].map((item, index) => (
              <ListItem key={index} sx={{ display: "list-item" }}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography paragraph sx={{ mt: 2 }}>
          En todos los casos, el interesado deberá acreditar de forma suficiente
          su identidad (copia de documento de identidad, poder, registro civil
          de defunción, etc., según corresponda).
        </Typography>
      </Section>

      {/* Sección XI. VIGENCIA Y MODIFICACIONES */}
      <Section title="XI. VIGENCIA Y MODIFICACIONES">
        <List dense>
          {[
            {
              title: "1. Vigencia:",
              points: [
                "Esta Política entra en vigor a partir de su publicación [fecha], y permanecerá vigente mientras Skala ejecute las actividades propias de su objeto social y mantenga el tratamiento de datos personales, o hasta que sea modificada de forma expresa.",
                "Los datos personales se conservarán durante el tiempo que sea necesario para cumplir con las finalidades descritas o mientras subsistan obligaciones legales o contractuales que así lo requieran.",
              ],
            },
            {
              title: "2. Modificaciones:",
              points: [
                "Skala se reserva el derecho de modificar parcial o totalmente esta Política en cualquier momento. Cuando ello ocurra, se anunciarán los cambios en la página web oficial y/o se notificará a los titulares a través de los canales de contacto que hayan sido autorizados.",
                "En caso de cambios sustanciales sobre las finalidades del tratamiento, se solicitará nuevamente la autorización del titular cuando sea legalmente requerido.",
              ],
            },
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "block", py: 0.5 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {item.title}
              </Typography>
              <List dense sx={{ pl: 2 }}>
                {item.points.map((point, pointIndex) => (
                  <ListItem
                    key={pointIndex}
                    sx={{ display: "list-item", listStyleType: "circle" }}
                  >
                    <ListItemText primary={point} />
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección XII. CONTACTO */}
      <Section title="XII. CONTACTO">
        <Typography paragraph>
          Para cualquier inquietud, duda, consulta o reclamo relacionado con
          esta Política de Tratamiento de Datos Personales, podrás dirigirte a:
        </Typography>
        <List dense sx={{ pl: 4, listStyleType: "disc" }}>
          {[
            "Skala Red Logística S.A.S.",
            "Teléfono:  +57 320 3085398",
            "Correo electrónico: contacto@skalaredlogistica.com",
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item", pl: 1 }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>
    </Container>
  );
};

// Componente auxiliar para secciones
const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography
      variant="h2"
      sx={{
        fontSize: "1.75rem",
        fontWeight: "bold",
        mb: 2,
        color: "#ed1b24",
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

// Componente auxiliar para definiciones
const DefinitionItem = ({
  term,
  definition,
}: {
  term: string;
  definition: string;
}) => (
  <ListItem sx={{ alignItems: "flex-start" }}>
    <ListItemText
      primary={
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {term}
        </Typography>
      }
      secondary={definition}
      secondaryTypographyProps={{ component: "span" }}
    />
  </ListItem>
);

const DataCollectionItem = ({
  title,
  descriptions,
}: {
  title: string;
  descriptions: string[];
}) => (
  <Box sx={{ mb: 3 }}>
    <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
      {title}
    </Typography>
    <List dense disablePadding sx={{ pl: 2 }}>
      {descriptions.map((desc, index) => (
        <ListItem key={index} sx={{ alignItems: "flex-start", py: 0 }}>
          <ListItemText
            primary={desc}
            primaryTypographyProps={{
              variant: "body1",
              component: "span",
              sx: { display: "list-item", listStyleType: "disc" },
            }}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

export default PrivacyPolicy;
