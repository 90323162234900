import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { Helmet } from "react-helmet";

const DataDeletionPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>Política de Eliminación de Datos - Skala Red Logística</title>
        <meta
          name="description"
          content="Política de eliminación de datos de usuario de Skala Red Logística S.A.S."
        />
      </Helmet>

      <Box mb={4}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            mb: 2,
            color: "#ed1b24",
          }}
        >
          POLÍTICA DE ELIMINACIÓN DE DATOS DE USUARIO
        </Typography>
        
        <Typography variant="h6" gutterBottom>
          Skala Red Logística S.A.S.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Última actualización: 30 de julio de 2024
        </Typography>
      </Box>

      {/* Sección 1. DERECHO A LA SUPRESIÓN */}
      <Section title="1. DERECHO A LA SUPRESIÓN">
        <Typography paragraph>
          De acuerdo con la Ley 1581 de 2012, usted puede solicitar la eliminación de sus datos personales almacenados en nuestras bases, excepto cuando:
        </Typography>
        <List dense sx={{ listStyleType: 'disc', pl: 4 }}>
          {[
            "Exista una obligación legal de conservación",
            "Los datos sean necesarios para reclamos judiciales",
            "La información esté vinculada a contratos vigentes"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: 'list-item' }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 2. PROCEDIMIENTO DE SOLICITUD */}
      <Section title="2. PROCEDIMIENTO DE SOLICITUD">
        <Typography paragraph>
          Para eliminar sus datos personales, siga estos pasos:
        </Typography>
        <List dense sx={{ listStyleType: 'decimal', pl: 4 }}>
          {[
            "Envíe un correo electrónico a leonardo.moyano@skalaredlogistica.com con el asunto: 'Solicitud de eliminación de datos'",
            "Adjunte copia de su documento de identidad",
            "Incluya descripción detallada de los datos a eliminar"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: 'list-item' }}>
              <ListItemText 
                primary={item}
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
        </List>
        <Typography paragraph sx={{ mt: 2 }}>
          Plazo máximo de respuesta: 15 días hábiles desde la recepción completa de la solicitud.
        </Typography>
      </Section>

      {/* Sección 3. DATOS QUE CONSERVAMOS */}
      <Section title="3. DATOS QUE CONSERVAMOS">
        <Typography paragraph>
          Mantendremos registros esenciales según requerimientos legales:
        </Typography>
        <List dense sx={{ listStyleType: 'disc', pl: 4 }}>
          {[
            "Información fiscal y contable (10 años)",
            "Historial de contratos de transporte (5 años)",
            "Registros de seguridad (georreferenciación, documentos de carga - 3 años)"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: 'list-item' }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 4. CONTACTO */}
      <Section title="4. CONTACTO">
        <Typography paragraph>
          Para solicitudes adicionales sobre tratamiento de datos:
        </Typography>
        <List dense>
          <ListItem>
            <Link href="mailto:leonardo.moyano@skalaredlogistica.com" color="primary">
              Correo electrónico: leonardo.moyano@skalaredlogistica.com
            </Link>
          </ListItem>
          <ListItem>
            <Link href="tel:+573108807815" color="primary">
              Teléfono: +57 320 3085398 
            </Link>
          </ListItem>
          <ListItem>
            <Typography>
              Dirección: Calle 9 E N° 53B-Sur-44, Jamundi, Valle del Cauca
            </Typography>
          </ListItem>
        </List>
      </Section>

      <Box mt={4} textAlign="center">
        <Typography variant="body2" color="text.secondary">
          *Esta política se rige por la Ley 1581 de 2012 y el Decreto 1377 de 2013
        </Typography>
      </Box>
    </Container>
  );
};

// Componente auxiliar para secciones (reutilizado de políticas anteriores)
const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography
      variant="h2"
      sx={{
        fontSize: "1.75rem",
        fontWeight: "bold",
        mb: 2,
        color: "#ed1b24",
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

export default DataDeletionPolicy;