import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>Términos y Condiciones - Skala Red Logística</title>
        <meta
          name="description"
          content="Términos y condiciones de uso de los servicios de Skala Red Logística S.A.S."
        />
      </Helmet>

      <Box mb={4}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            mb: 2,
            color: "#ed1b24",
          }}
        >
          TÉRMINOS Y CONDICIONES DE USO
        </Typography>

        <Typography variant="h6" gutterBottom>
          Skala Red Logística S.A.S.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Última actualización: 30 de julio de 2024
        </Typography>
      </Box>

      {/* Sección 1. ACEPTACIÓN DE TÉRMINOS */}
      <Section title="1. ACEPTACIÓN DE TÉRMINOS">
        <Typography paragraph>
          Al utilizar los servicios de Skala Red Logística S.A.S. (NIT: 900701615-2), domiciliada en Jamundi, Valle del Cauca (Calle 9 E N° 53B-Sur-44), usted acepta los siguientes términos y condiciones. Estos aplican para:
        </Typography>
        <List dense>
          {[
            "Plataforma web: https://www.skalaredlogistica.com",
            "Aplicaciones móviles",
            "Servicios de mensajería (WhatsApp, correo electrónico, etc.)",
            "Soluciones de software logístico."
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 2. SERVICIOS OFRECIDOS */}
      <Section title="2. SERVICIOS OFRECIDOS">
        <Typography paragraph>
          Skala Red Logística está legalmente habilitada para prestar servicios de transporte terrestre automotor de carga (Resolución No. 20243760032165 del Ministerio de Transporte, julio 2024), incluyendo:
        </Typography>
        <List dense>
          {[
            "Transporte multimodal de mercancías",
            "Logística, consolidación y distribución de carga",
            "Servicios postales y mensajería especializada",
            "Consultoría en optimización de procesos logísticos"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 3. OBLIGACIONES DEL USUARIO */}
      <Section title="3. OBLIGACIONES DEL USUARIO">
        <List dense>
          {[
            "Proporcionar información veraz y actualizada",
            "Cumplir con normativas de transporte de carga colombianas",
            "Verificar que las mercancías cumplan con requisitos legales para su transporte",
            "Abstenerse de utilizar los servicios para actividades ilícitas"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 4. PROPIEDAD INTELECTUAL */}
      <Section title="4. PROPIEDAD INTELECTUAL">
        <Typography paragraph>
          Todos los contenidos (software, diseños, marcas) son propiedad de Skala Red Logística S.A.S. Queda prohibida su reproducción sin autorización escrita.
        </Typography>
      </Section>

      {/* Sección 5. LIMITACIÓN DE RESPONSABILIDAD */}
      <Section title="5. LIMITACIÓN DE RESPONSABILIDAD">
        <Typography paragraph>
          Skala no se responsabiliza por:
        </Typography>
        <List dense>
          {[
            "Daños por fuerza mayor o caso fortuito",
            "Pérdidas causadas por información incorrecta del usuario",
            "Retrasos por condiciones climáticas o restricciones viales"
          ].map((item, index) => (
            <ListItem key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Sección 6. MODIFICACIONES */}
      <Section title="6. MODIFICACIONES">
        <Typography paragraph>
          Nos reservamos el derecho de actualizar estos términos. Los cambios se publicarán en nuestra plataforma con 15 días de antelación.
        </Typography>
      </Section>

      {/* Sección 7. JURISDICCIÓN */}
      <Section title="7. JURISDICCIÓN">
        <Typography paragraph>
          Cualquier disputa se resolverá bajo las leyes colombianas, en los tribunales de Cali, Valle del Cauca.
        </Typography>
      </Section>

      {/* Sección de Contacto */}
      <Section title="Contacto">
        <List dense>
          <ListItem>
            <Link href="tel:+573108807815" color="primary">
              Teléfono: +57 320 3085398 
            </Link>
          </ListItem>
          <ListItem>
            <Link href="mailto:leonardo.moyano@skalaredlogistica.com" color="primary">
              Correo: leonardo.moyano@skalaredlogistica.com
            </Link>
          </ListItem>
        </List>
      </Section>
    </Container>
  );
};

// Componente auxiliar para secciones (reutilizado de PrivacyPolicy)
const Section = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography
      variant="h2"
      sx={{
        fontSize: "1.75rem",
        fontWeight: "bold",
        mb: 2,
        color: "#ed1b24",
      }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

export default TermsOfService;