import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Hero() {
  return (
    <>
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(0, 87%, 92%), transparent)",
          ...theme.applyStyles("dark", {
            backgroundImage:
              "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(0, 87%, 90%), transparent)",
          }),
          "&::before": {
            content: '""',
            position: "absolute",
            // top: '-10%',
            // left: '-10%',
            width: "100%",
            height: "100%",
            backgroundImage: `url('/static/images/TruckSkala.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            opacity: 0.1,
            zIndex: 0,
            filter: "grayscale(0%)",
          },
        })}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ alignItems: "center", width: { xs: "100%", sm: "80%" } }}
          >
            <Typography
              variant="h1"
              sx={{
                textAlign: "center",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                fontSize: "clamp(3rem, 10vw, 3.5rem)",
              }}
            >
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: "inherit",
                  textAlign: "center",
                  color: "primary.main",
                  ...theme.applyStyles("dark", {
                    color: "primary.light",
                  }),
                })}
              >
                Skala Red Logística
              </Typography>
              &nbsp; Conectamos, Optimizamos y Protegemos
            </Typography>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "text.secondary",
                width: { sm: "100%", md: "80%" },
              }}
            >
              Soluciones Integrales para el Transporte de Mercancías
              Especializadas
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "text.secondary",
                width: { sm: "100%", md: "80%" },
              }}
            >
              {" "}
              En &nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: "inherit",
                  textAlign: "center",
                  color: "primary.main",
                  ...theme.applyStyles("dark", {
                    color: "primary.light",
                  }),
                })}
              >
                Skala Red Logística
              </Typography>
              &nbsp; Somos expertos en conectar transportadores, transportadoras
              y generadores de carga para optimizar el transporte de mercancías
              especializadas, tanto en entornos urbanos como a nivel nacional.
              Nuestra misión es garantizar que cada envío llegue a su destino de
              manera segura, eficiente y rentable.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "text.secondary",
                width: { sm: "100%", md: "80%" },
              }}
            >
              Contamos con tráileres con tecnología activa en tiempo real de
              seguimiento equipados con infraestructura de doble doble nivel y
              con medidas sobredimensionados, (de 16m de largo y 2.60m de ancho)
              que permiten transportar mayor carga y reducir costos logísticos
              hasta en un 40%.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
